import React, { useState } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { useArticles } from '../ArticleContext';


const SearchBox = () => {

  const [searchTerm, setSearchTerm] = useState('');
  const { fetchArticles, locale } = useArticles();

  const handleSearch = () => {
    // Implement your search logic here
    fetchArticles(searchTerm);
  };

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleKeyPress = (event) => {
    // Trigger search when Enter key is pressed
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const labels = {
    "ar": "ابحث عن مقال", 
    "en-US": "Search for an Article"
  }

    const cssRules = {
      'searchBoxDiv': {
        'en-US': 'flex flex-row items-center space-x-2',
        "ar": 'flex flex-row-reverse items-center space-x-2 space-x-reverse'
    },
    'searchBoxInput': {
      'en-US': 'p-2 border border-gray-300 rounded-md w-full',
      'ar': 'p-2 border border-gray-300 rounded-md w-full text-right'
    }
  }

  return (
    <div className={locale === 'ar' ? cssRules['searchBoxDiv']['ar'] : cssRules['searchBoxDiv']['en-US']}>
      <input
        type="text"
        placeholder={locale === 'ar' ? labels['ar'] : labels['en-US']}
        value={searchTerm}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        className={locale === 'ar' ? cssRules['searchBoxInput']['ar'] : cssRules['searchBoxInput']['en-US']}
      />
      <button
        onClick={handleSearch}
        className="p-2 bg-blue-500 text-blue-light-200 border border-gray-300 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-500 focus:ring-opacity-50"
      >
        <MagnifyingGlassIcon className="h-5 w-5"/>
      </button>
    </div>
  );
};

export default SearchBox;