import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useArticles } from './../ArticleContext';


function LargeCard({article}){

    const {locale } = useArticles();

    const isRTL = () => {
        // get the stored locale 
        if(locale){
          return locale === 'ar' ? true : false
        }
        return true
      }

      const styles = {
        'ar'   : 'text-right border border-light-blue-200 rounded-t-lg overflow-hidden bg-light-blue-100',
        'en-US': 'text-left border border-light-blue-200 rounded-t-lg overflow-hidden bg-light-blue-100'
      }

      return (
        <div className={isRTL() ? styles['ar'] : styles['en-US'] }>
                <div className="aspect-w-16 aspect-h-9">
                  <img
                    src={article.image_path}
                    alt={article.title}
                    className="object-cover w-full h-full"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "https://placehold.co/600x400"; // replace with your placeholder image path
                    }}
                  />
                </div>
                <div className="bg-white p-5">
                  <h1 className='font-bold text-2xl mb-3 text-center'>
                    {article.fields.title}
                  </h1>
                  <div className='font-regular leading-7 text-justify [text-align-last:right]'>{ documentToReactComponents(article.fields.body) }</div>
                </div>

        </div>
      );
}
export default LargeCard;