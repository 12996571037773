import './App.css';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import ArticlesList from './ArticlesList';
import ArticleDetail from './ArticleDetail';
import { ArticleProvider } from './ArticleContext';


function App() {
  return (
    <ArticleProvider>
      <Router>
        <Routes>
          <Route path="/" element={ <ArticlesList />} />
          <Route path="/article/:articleId" element={<ArticleDetail />} />
        </Routes>
      </Router>
    </ArticleProvider>
  );
}


export default App;
