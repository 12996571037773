import React from 'react';
import ArticleCard from './Card';
import { useArticles } from './../ArticleContext';


const AList = ({ articles }) => {

  const { locale } = useArticles();

  const messages = {
    'ar'   : 'عذراً ، لا توجد مقالات منشورة. جرب البحث بكلمات أخرى',
    'en-US': 'Sorry, No Articles found.'
  }

  const styles = {
    'ar': 'mr-5 text-right',
    'en-US': 'ml-5 text-left'
  }

  if(articles.length === 0){
    console.log('No Articles');
    return(
      <div className="bg-light-blue-100 container mx-auto p-2">
        <div className="bg-light-blue-100 container mx-auto p-2">
          <div className={locale === 'ar' ? styles['ar'] : styles['en-US']}>
            <h2>{ locale === 'ar' ? messages['ar'] : messages['en-US'] }</h2>
          </div>
      </div>
    </div>
    );
  }

  return (
    <div className="container mx-auto p-2">
      <div className="grid grid-flow-row auto-rows-max rtl-grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
        {articles.map((article, index) => (
          <div key={index} className="">
            <ArticleCard article={article} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AList;