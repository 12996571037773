import React, { createContext, useState, useContext, useEffect } from 'react';
import {contentfulConfig} from './contentful';
import axios from 'axios';

const ArticleContext = createContext();

export const useArticles = () => useContext(ArticleContext);

export const ArticleProvider = ({ children }) => {
  const [articles, setArticles] = useState([]);
  const [locale, setLocale] = useState('ar'); // Default locale
  const [query, setQuery] = useState('');


  const fetchArticles = async (searchTerm = '') => {
    
    setQuery(searchTerm)
        
    let URL = `https://cdn.contentful.com/spaces/${contentfulConfig.spaceId}/environments/${contentfulConfig.environment}/entries?access_token=${contentfulConfig.accessToken}&content_type=${contentfulConfig.contentTypeIds.article}&locale=${locale}`
    
    if(query !== ''){
        URL += `&query=${searchTerm}}`;
    }

    try {
        const response = await axios.get(URL);
        setArticles(response.data.items);
    } catch (error) {
        console.error("Error fetching data: ", error);
    }
  };


  const updateArticle = (updatedArticle) => {
    setArticles((prevArticles) => {
      return prevArticles.map((article) => {
        if (article.sys.id === updatedArticle.id) {
          return updatedArticle;
        }
        return article; // Return the original article
      });
    });
  };

  useEffect(() => {
    fetchArticles(query);
  }, [locale, query]);

  const value = { articles, fetchArticles, updateArticle, locale, setLocale};

  return <ArticleContext.Provider value={value}>{children}</ArticleContext.Provider>;
};