import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {contentfulConfig} from '../contentful';
import axios from 'axios';
import { useArticles } from '../ArticleContext';


const imageCache = new Map();

function Card({ article }){

  const [isImageLoading, setIsImageLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState('');
  const { updateArticle, locale } = useArticles();


  const isRTL = () => {
    // get the stored locale 
    if(locale){
      return locale === 'ar' ? true : false
    }
    return true
  }
  useEffect(() => {

    const fetchImage = async () => {
      try {
        // Handle Image Fetching 
        if (article && article.fields.image) {
            const assetId = article?.fields?.image?.sys?.id;
            
            if(imageCache.has(assetId)){
              console.log("Image is in cache");
              setImageUrl(imageCache.get(assetId));
              return;
            }

            setIsImageLoading(true);
            await axios.get(`https://cdn.contentful.com/spaces/${contentfulConfig.spaceId}/environments/${contentfulConfig.environment}/assets/${assetId}?access_token=${contentfulConfig.accessToken}`)
            .then((response) => {
                const fetchedImageUrl   = response.data.fields.file.url;
                const optimizedImageURL = `${fetchedImageUrl}?fm=webp&q=100&w=500`
                setIsImageLoading(false);
                setImageUrl(optimizedImageURL)
                // Update the Articles Context
                updateArticle({...article, image_path: optimizedImageURL});
                console.log("Article is updated")
                // Cache the fetched image URL with the asset ID as the key
                imageCache.set(assetId, optimizedImageURL);
            })
          }
      } catch (error) {
        console.error("Error fetching Image: ", error);
      }
    };

    fetchImage();
  }, [article]);


  function storeArticle(){

   
   const storedArticle = {...article, image_path: imageUrl};
   localStorage.setItem('active_article', JSON.stringify(storedArticle));
  }


  return (
    <div className={isRTL() ? 'text-right' : 'text-left bg-white border border-light-blue-200 rounded-lg overflow-hidden'}>
       <Link to={`/article/${article.sys.id}`} onClick={(e) => storeArticle()}>
            <div className="aspect-w-16 aspect-h-9">
              <img
                src={imageUrl}
                alt={article.title}
                loading="lazy"
                className="object-cover w-full h-full"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "https://placehold.co/600x400"; // replace with your placeholder image path
                }}
              />
            </div>
            <div className="bg-white p-4">
              <h2 className={isRTL() ? 'text-right' : 'text-left'}>{article.fields.title}</h2>
            </div>
        </Link>
    </div>
  );
}
export default Card;