import React, { useState, useEffect } from 'react';
import LargeCard from './components/LargeCard';
function ArticleDetail() {

  // update for language param. 
  const [x, setX] = useState(null)

  useEffect(() => {
    const storedArticle = JSON.parse(localStorage.getItem('active_article'));
    setX(storedArticle);
  }, []);

  if(!x){
    return <p>Loading ... </p>
  }

  return (
    <div className='mx-auto p-10 bg-light-blue-100'>
        <LargeCard article={x} />
    </div>
  );
}

export default ArticleDetail;