// src/ArticlesList.js

import React, {useEffect} from 'react';
import { useLocation } from 'react-router-dom'; // assuming you're using react-router
import List from './components/List';
import SearchBox from './components/SearchBox';
import { useArticles } from './ArticleContext';


function ArticlesList() {
  const { search } = useLocation();
  const { articles, fetchArticles, setLocale } = useArticles();


    useEffect(() => {
      const queryParams = new URLSearchParams(search);
      const localeFromURL = queryParams.get('locale');
      if (localeFromURL) {
        setLocale(localeFromURL); // Update the locale in the context
      }
      fetchArticles(); // You can replace 'en-US' with the actual locale you want to fetch
    }, [search, setLocale]);


  return (
    <div className='font-regular flex-col bg-light-blue-100'>
      <div className='container mx-auto p-2'>
        <SearchBox />
      </div>
      
      <div className=''>
        {articles &&
          <List articles={articles} />
        }
      </div>
    </div>
  );
}

export default ArticlesList;